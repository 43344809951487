import React from 'react'
import Products from './Products'


function Home() {

    return (
        <>
            <Products />
        </>
    )
}

export default Home